import { render, staticRenderFns } from "./rmsSegmentReport.vue?vue&type=template&id=16cb0318&scoped=true"
import script from "./rmsSegmentReport.vue?vue&type=script&lang=js"
export * from "./rmsSegmentReport.vue?vue&type=script&lang=js"
import style0 from "./rmsSegmentReport.vue?vue&type=style&index=0&id=16cb0318&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16cb0318",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VSelect})
