<template>
  <a-page
    :image_source="image_source"
    :image_height="image_height"
    :mobile_image_height="mobile_image_height"
  >
    <template v-slot:header_options v-if="!isGroup">
      <a-btn-pdf
        v-if="has_permission('export_pdf')"
        :config="pdf_config"
        class="mt-2 mr-4"
      ></a-btn-pdf>
      <div class="select-wrap" v-if="show_production_mode">
        <v-select
          v-model="commission"
          :items="commission_types"
          item-text="name"
          item-value="id"
          :label="$t('billing_type')"
          :rules="[v => !!v || $t('required')]"
          class="mt-8"
          color="#fff"
          rounded
          outlined
          dense
        >
        </v-select>
      </div>
      <div class="select-wrap">
        <v-select
          v-model="breakdown"
          :items="breakdown_types"
          item-text="name"
          item-value="id"
          :label="$t('breakdown')"
          :rules="[v => !!v || $t('required')]"
          class="mt-8"
          color="#fff"
          rounded
          outlined
          dense
        >
        </v-select>
      </div>
      <a-rms-range-selector
        ref="a-rms-range-selector"
        :event_period="[segmentation_report_from, segmentation_report_to]"
        @updatePeriod="updateRange"
        class="mt-1 mr-2"
      >
      </a-rms-range-selector>
      <a-rms-import-dates
        ref="rms_import_dates"
        class="mt-1"
      ></a-rms-import-dates>
    </template>
    <a-hotel-selector-rms
      ref="a-hotel-selector-rms"
      v-if="isGroup"
    ></a-hotel-selector-rms>
    <rms-segmentation-report-app
      ref="rms-segment-report-hotelsdot-app"
      dimension="segment"
      v-else
    ></rms-segmentation-report-app>
  </a-page>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import aPage from "@/components/aPage";
import aRmsRangeSelector from "@/components/rms/aRmsRangeSelector";
import rmsSegmentationReportApp from "@/components/app/hotelsdot/rmsSegmentationReportApp.vue";
import aHotelSelectorRms from "@/components/selector/aHotelSelectorRms.vue";
import aRmsImportDates from "@/components/rms/aRmsImportDates";
import aBtnPdf from "@/components/aBtnPdf";

export default {
  name: "rms-segment-report",
  components: {
    aHotelSelectorRms,
    aPage,
    aRmsRangeSelector,
    rmsSegmentationReportApp,
    aRmsImportDates,
    aBtnPdf
  },
  data: () => ({
    image_source: "/img/header.jpg", //Mandatory path
    mobile_image_height: 1200 // Optional number,
  }),
  methods: {
    updateRange(v) {
      let range = JSON.parse(JSON.stringify(v));
      this.segmentation_report_from = range[0];
      this.segmentation_report_to = range[1];
    },
    getFriendlyDate(date) {
      return Vue.moment(date).format("DD/MM/YYYY");
    }
  },
  computed: {
    isGroup() {
      return this.current_hotel && this.current_hotel.children_number > 0;
    },
    image_height() {
      return !this.isGroup ? 693 : 200;
    },
    show_production_mode() {
      return this.current_user && this.current_hotel
        ? this.current_user.permissions.rms_production_mode &&
            this.current_hotel.permissions.rms_production_mode
        : false;
    },
    commission: {
      get() {
        return this.$store.state.rms.segmentation_report_commission;
      },
      set(value) {
        this.$store.dispatch("setRmsSegmentationReportCommission", value);
      }
    },
    breakdown: {
      get() {
        return this.$store.state.rms.segmentation_report_breakdown;
      },
      set(value) {
        this.$store.dispatch("setRmsSegmentationReportBreakdown", value);
      }
    },
    segmentation_report_from: {
      get() {
        return this.$store.state.rms.segmentation_report_from;
      },
      set(value) {
        this.$store.dispatch("setRmsSegmentationReportFrom", value);
      }
    },
    segmentation_report_to: {
      get() {
        return this.$store.state.rms.segmentation_report_to;
      },
      set(value) {
        this.$store.dispatch("setRmsSegmentationReportTo", value);
      }
    },
    commission_types() {
      return [
        { id: "mixed", name: this.$t("mixed") },
        { id: "gross", name: this.$t("gross") },
        { id: "net", name: this.$t("net") }
      ];
    },
    breakdown_types() {
      return [
        { id: "daily", name: this.$t("daily") },
        { id: "monthly", name: this.$t("monthly") }
      ];
    },
    pdf_config() {
      return {
        filename:
          this.$t("segments-report") +
          " - " +
          this.current_hotel.name +
          " -- " +
          this.getFriendlyDate(this.segmentation_report_from) +
          " - " +
          this.getFriendlyDate(this.segmentation_report_to),
        elements: [
          {
            id: "rms-segmentation-report-chart",
            is_table: false
          },
          {
            id: "rms-segmentation-report-table",
            is_table: false
          }
        ],
        hotel_name: this.current_hotel.name,
        section_name: this.$t("segments-report")
      };
    },
    ...mapGetters({
      current_hotel: "current_hotel",
      current_user: "current_user",
      has_permission: "has_permission"
    })
  },
  created() {
    // Initialize selector
    this.segmentation_report_from = Vue.moment()
      .startOf("year")
      .format("YYYY-MM-DD");
    this.segmentation_report_to = Vue.moment()
      .endOf("year")
      .format("YYYY-MM-DD");
    this.$store.dispatch(
      "setRmsSegmentationReportCommission",
      this.commission_types[0].id
    );
    this.$store.dispatch(
      "setRmsSegmentationReportBreakdown",
      this.breakdown_types[1].id
    );
  }
};
</script>
<style lang="scss" scoped>
.select-wrap {
  max-width: 200px;
  margin-right: 10px;
}
</style>
